import clone from 'lodash-es/clone'
import some from 'lodash-es/some'
import { computed, ref, Ref } from 'vue'

import {
    checkImprovement,
    OnPushHandler,
    useImprovement,
    UseImprovement,
} from '@/composition/improvement/useImprovement'
import { extractIdFromResourceName } from '@/lib/globalUtils/extractIdFromResourceName'
import { Entity } from '@opteo/types'

interface AddMissingCampaignsToSharedSetBody {
    campaign_id: string
    campaign_label: string
    primary_shared_set_id: string
    primary_shared_set: {
        shared_set_id: string
        reference_count: number
        shared_set_name: string
        state: string
    }
    all_shared_sets: SharedSet[]
}

interface SharedSet {
    shared_set_id: string
    reference_count: number
    shared_set_name: string
    state: string
    selected?: boolean
}

interface UseAddMissingCampaignsToSharedSet {
    campaignName: string
    campaignId: number
    error: Ref<string | null>
    primarySharedSet: Omit<SharedSet, 'shared_set_id'> & { shared_set_id: number }
    sharedSets: Ref<SharedSet[]>
    resetSelection: () => void
}

export function useAddMissingCampaignsToSharedSet(): UseImprovement<UseAddMissingCampaignsToSharedSet> {
    const sharedSets = ref<SharedSet[]>([])
    const error = ref<string | null>(null)
    const { improvement, lastUpdated } = useImprovement<AddMissingCampaignsToSharedSetBody>()

    const {
        body: { all_shared_sets, campaign_label, primary_shared_set, campaign_id },
    } = checkImprovement(improvement)

    const campaignName = campaign_label
    const campaignId = extractIdFromResourceName(campaign_id, Entity.EntityLocation.Campaign)
    const primarySharedSet = {
        ...primary_shared_set,
        shared_set_id: extractIdFromResourceName(
            primary_shared_set.shared_set_id,
            Entity.EntityLocation.NegativeList
        ),
    }

    sharedSets.value = clone(all_shared_sets)

    sharedSets.value.forEach(sharedSet => {
        sharedSet.selected =
            extractIdFromResourceName(
                sharedSet.shared_set_id,
                Entity.EntityLocation.NegativeList
            ) === primarySharedSet.shared_set_id
    })

    const title = computed(() => {
        return sharedSets.value.filter(sharedSet => sharedSet.selected).length > 1
            ? 'Apply Negative Lists'
            : 'Apply Negative List'
    })

    const pushActionText = ref(title)

    function resetSelection() {
        sharedSets.value.forEach(sharedSet => {
            sharedSet.selected =
                extractIdFromResourceName(
                    sharedSet.shared_set_id,
                    Entity.EntityLocation.NegativeList
                ) === primarySharedSet.shared_set_id
        })
    }

    const onPush: OnPushHandler<{ campaign_id: string; shared_sets: SharedSet[] } | {}> = () => {
        const valid = validate()

        const setsSelected = sharedSets.value.filter(sharedSet => sharedSet.selected)

        if (
            setsSelected.length === 1 &&
            extractIdFromResourceName(
                setsSelected?.[0].shared_set_id,
                Entity.EntityLocation.NegativeList
            ) === primarySharedSet.shared_set_id
        ) {
            return { valid }
        }

        const pushedData = {
            campaign_id: campaign_id,
            shared_sets: sharedSets.value,
        }

        return {
            valid,
            pushedData,
        }
    }

    function validate() {
        error.value = null
        const sharedSetSelected = some(sharedSets.value, 'selected')

        if (!sharedSetSelected) {
            error.value = 'Please select at least one negative list.'
            return false
        }
        return true
    }

    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Finding campaign and lists..',
        `Confirming changes..`,
        `Negative list(s) applied successfully.`,
    ])

    return {
        lastUpdated,
        title,
        pushMessages,
        sharedSets,
        primarySharedSet,
        campaignName,
        campaignId,
        onPush,
        error,
        pushActionText,
        resetSelection,
    }
}
